import React from 'react'
import styled, { css } from 'styled-components'

import { media } from 'utils/Media'

const MaxWidthStyles = styled.section`
  position: relative;
  width: 100%;
  background-color: var(--white);
  .page-width {
    width: 90%;
    margin: var(--auto);
    max-width: var(--maxWidth);
  }
  ${props =>
    props.$footer &&
    css`
      background-color: var(--background);
    `}
`
const MaxWidth = props => (
  <MaxWidthStyles {...props}>
    <div className="page-width">{props.children}</div>
  </MaxWidthStyles>
)

export default MaxWidth
