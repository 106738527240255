import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const HamburgerStyles = styled.button`
  border: none;
  background-color: transparent !important;
  cursor: pointer;
  section {
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: var(--transition);
    div {
      transform-origin: 3px;
      transition: var(--transition);
      width: 100%;
      height: 3px;
      background-color: var(--alt);
      border-radius: 5px;
    }
  }
  @media ${media.md} {
    display: none;
  }
`
const Hamburger = ({ open, setOpen }) => {
  const i = true
  return (
    <HamburgerStyles onClick={() => setOpen(!open)}>
      <section className={open ? 'hamburger-active' : ''}>
        <div />
        <div />
        <div />
      </section>
    </HamburgerStyles>
  )
}

export default Hamburger
