import { css } from 'styled-components'

import GothamThinWOFF2 from 'utils/Fonts/GothamThin.woff2'
import GothamThinWOFF from 'utils/Fonts/GothamThin.woff'

import GothamExtraLightWOFF2 from 'utils/Fonts/GothamExtraLight.woff2'
import GothamExtraLightWOFF from 'utils/Fonts/GothamExtraLight.woff'

import GothamLightWOFF2 from 'utils/Fonts/GothamLight.woff2'
import GothamLightWOFF from 'utils/Fonts/GothamLight.woff'

import GothamBookWOFF2 from 'utils/Fonts/GothamBook.woff2'
import GothamBookWOFF from 'utils/Fonts/GothamBook.woff'

import GothamMediumWOFF2 from 'utils/Fonts/GothamMedium.woff2'
import GothamMediumWOFF from 'utils/Fonts/GothamMedium.woff'

import GothamBoldWOFF2 from 'utils/Fonts/GothamBold.woff2'
import GothamBoldWOFF from 'utils/Fonts/GothamBold.woff'

import GothamBlackWOFF2 from 'utils/Fonts/GothamBlack.woff2'
import GothamBlackWOFF from 'utils/Fonts/GothamBlack.woff'

import GothamUltraWOFF2 from 'utils/Fonts/GothamUltra.woff2'
import GothamUltraWOFF from 'utils/Fonts/GothamUltra.woff'

const Typography = css`
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamThinWOFF2}) format('woff2'),
      url(${GothamThinWOFF}) format('woff');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamExtraLightWOFF2}) format('woff2'),
      url(${GothamExtraLightWOFF}) format('woff');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamLightWOFF2}) format('woff2'),
      url(${GothamLightWOFF}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBookWOFF2}) format('woff2'),
      url(${GothamBookWOFF}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamMediumWOFF2}) format('woff2'),
      url(${GothamMediumWOFF}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBoldWOFF2}) format('woff2'),
      url(${GothamBoldWOFF}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBlackWOFF2}) format('woff2'),
      url(${GothamBlackWOFF}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamUltraWOFF2}) format('woff2'),
      url(${GothamUltraWOFF}) format('woff');
    font-weight: 800;
    font-style: normal;
  }
`

export default Typography
