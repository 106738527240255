import Hamburger from 'components/functional/hamburger'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import GenerationLogo from 'svgs/Test.svg'
import { media } from 'utils/Media'
import Menu from './menu'
import DesktopNav from './desktopNav'

const StyledNav = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.85);
  /* background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  ); */

  .wrapper {
    position: relative;
    max-width: var(--maxWidth);
    width: 90%;
    margin: var(--auto);
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media ${media.md} {
      justify-content: flex-end;
      align-items: center;
      padding: 2.5rem 0;
    }
    @media ${media.lg} {
      justify-content: center;
    }
    .logo {
      a {
        width: 150px;
        display: flex;
        align-items: center;
        @media ${media.mediumMobile} {
          width: 200px;
        }
        @media ${media.largeMobile} {
          width: 250px;
        }
      }
      @media ${media.md} {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 300px !important;
          }
        }
      }
    }
  }
`

const Nav = () => {
  const [open, setOpen] = useState(false)
  return (
    <StyledNav>
      <section className="wrapper">
        <div className="logo">
          <Link to="/">
            <GenerationLogo />
            <span className="sr-only">CLICK HERE</span>
          </Link>
        </div>
        <DesktopNav />
        <Hamburger open={open} setOpen={setOpen} />
      </section>
      <Menu open={open} />
    </StyledNav>
  )
}

export default Nav
