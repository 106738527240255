import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import JewishNews from 'svgs/Jewish-News.svg'
import Trust from 'svgs/HMDT_Trust_logo_RGB.svg'
import { media } from 'utils/Media'
import { StaticImage } from 'gatsby-plugin-image'
import MaxWidth from './maxWidth'

const FooterStyles = styled.footer`
  width: 100%;
  padding: 2rem 0;
  section {
    width: 90%;
    margin: var(--auto);
    .logos {
      width: 70%;
      margin: var(--auto);
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      max-width: 600px;
      .larger {
        margin: 0 auto;
        max-width: 150px;
      }
      svg {
        height: auto;
        max-width: 150px;
        margin: 0 auto;
      }
      .gatsby-image-wrapper {
        width: 100%;
        max-width: 150px;
        margin: 0 auto;
        /* img {
          object-fit: fill !important;
        } */
      }
      @media ${media.md} {
        flex-direction: row;
        align-items: baseline;
      }
    }
    ul {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      flex-direction: column;
      gap: 0.5rem;
      li {
        color: var(--white);
        a {
          transition: var(--transition);
          &:hover,
          &:active {
            text-decoration: underline solid 2px;
          }
        }

        @media ${media.md} {
          a,
          p {
            font-size: 1rem !important;
          }
        }
      }
    }
  }
`

const Footer = () => {
  const { links } = useStaticQuery(graphql`
    query FooterQuery {
      links: allLegalPagesJson {
        nodes {
          title
          slug
        }
      }
    }
  `)
  const legalPages = links.nodes

  return (
    <MaxWidth $footer>
      <FooterStyles>
        <section>
          <div className="logos">
            <StaticImage
              src="../../images/logos/RPS_Logo.jpeg"
              alt="A dinosaur"
              placeholder="blurred"
              layout="constrained"
            />
            <JewishNews />
            <Trust className="larger" />
          </div>
          <ul>
            {legalPages.map((page, i) => (
              <li key={i}>
                <Link to={`${page.slug}`}>{page.title}</Link>
              </li>
            ))}
            <li>
              <p>Generations &copy; {new Date().getFullYear()}</p>
            </li>
          </ul>
        </section>
      </FooterStyles>
    </MaxWidth>
  )
}

export default Footer
