import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const MenuStyles = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(24px);
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  section {
    width: 80%;
    height: 100%;
    margin: var(--auto);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    ul {
      list-style: none;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      li {
        color: var(--alt);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2rem;
        &:hover,
        &:active {
          text-decoration: underline 4px;
        }
        animation: slide-in-left 0.75s 0.25s ease-in-out both;
        &:nth-child(1) {
        }
        &:nth-child(2) {
          animation: slide-in-right 0.75s 0.5s ease-in-out both;
        }
        &:nth-child(3) {
          animation-delay: 0.75s;
        }
      }
    }
  }
`
const Menu = ({ open }) => {
  const i = true
  return open ? (
    <MenuStyles>
      <section>
        <ul>
          {/* <li>
            <Link to="/our-story/">
              Our Story
              <span className="sr-only">CLICK HERE</span>
            </Link>
          </li> */}
          <li>
            <Link to="/gallery/">
              Gallery
              <span className="sr-only">CLICK HERE</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/contact/">
              Contact
              <span className="sr-only">CLICK HERE</span>
            </Link>
          </li> */}
        </ul>
      </section>
    </MenuStyles>
  ) : (
    ''
  )
}

export default Menu
