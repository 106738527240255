import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const DestopNavStyles = styled.nav`
  display: none;
  @media ${media.md} {
    display: block;
    position: relative;
    z-index: 999;
    ul {
      display: flex;
      gap: 1.5rem;
      list-style: none;
      li {
        a {
          color: var(--alt);
          font-weight: 500;
          text-transform: uppercase;
          font-size: 1.2rem;
          &:hover,
          &:active {
            border-bottom: solid 3px var(--white);
          }
        }
      }
    }
  }
`
const DestopNav = () => (
  <DestopNavStyles>
    <section>
      <ul>
        <li>
          {/* <Link to="/our-story/">
            Our Story
            <span className="sr-only">CLICK HERE</span>
          </Link> */}
        </li>
        <li>
          <Link to="/gallery/">
            Gallery
            <span className="sr-only">CLICK HERE</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/contact/">
            Contact
            <span className="sr-only">CLICK HERE</span>
          </Link>
        </li> */}
      </ul>
    </section>
  </DestopNavStyles>
)

export default DestopNav
